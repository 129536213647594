import React from 'react';
import { graphql } from 'gatsby';

import Column from '../components/column';
import Container from '../components/container';
import CtaImage, { CtaImageRow } from '../components/cta-image';
import Heading from '../components/heading';
import HomeHero from '../components/home-hero';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section, SectionBase } from '../components/section';
import { Iframe, IframeContainer } from '../components/iframe';

const Home = ({
  data: {
    hero,
    image1,
    image2,
    image3,
    image4,
  },
}) => (
  <Layout>
    <HomeHero image={hero.image} />
    <Section id="show-more">
      <Container>
        <Row>
          <Column offset={2}>
            <Heading as="h2">Let's meet Polish Boogie Festival</Heading>
          </Column>
        </Row>
        <Row align="center">
          <Column offset={3}>
            <p>
              Polish Boogie Festival is a journey back in time to the era when boogie-woogie, blues, rockabilly and rock’n’roll reigned on stages all across America. The journey to times when beautiful Pin Up Girls walked the streets and gentlemen fought for their hearts at café racer sprints. It is finally a journey to the golden age of cinema, with Fred Astaire and Ginger Rogers on screens - the admired masters of dance performances.
            </p>
            <p>
              Polish Boogie Festival is a music feast enriched by the most beautiful flavours of the 40’s, 50’s and 60’s culture.
              Every year the dance stage and the music stage host the masters of both categories, giving them space to perform unforgettable jams underneath the summer sky.
              Classic cars, vintage fashion, wonderful people and beautiful landscapes of Człuchów make Polish Boogie Festival an event one of a kind

            </p>
          </Column>
        </Row>
      </Container>
      <Container fluid>
        <CtaImageRow>
          <CtaImage link="/music" title="music" image={image1.image} />
          <CtaImage link="/dance" title="dance" image={image2.image} />
          <CtaImage link="/pin-up" title="pin-up" image={image3.image} />
          <CtaImage link="/motors" title="motors" image={image4.image} />
        </CtaImageRow>
      </Container>
    </Section>
    <SectionBase>
      <Container>
        <Row justify="center">
          <Column>
            <Heading as="h2">
              Since 2004 the biggest boogie-woogie festival in central Europe!
            </Heading>
          </Column>
        </Row>
      </Container>
    </SectionBase>
    <Section>
      <Container>
        <Row justify="center">
          <IframeContainer>
            <Iframe title="vimeo-player" src="https://player.vimeo.com/video/685842557?h=6a2f1542ee" frameBorder="0" allowFullScreen />
          </IframeContainer>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default Home;

export const IndexQuery = graphql`
  query IndexQuery {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 1105, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    image1: file(relativePath: { eq: "festiwal-1.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 368, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    image2: file(relativePath: { eq: "festiwal-2.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 368, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "festiwal-3.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 368, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    image4: file(relativePath: { eq: "festiwal-4.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 368, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`;
