import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { between, up } from 'styled-breakpoints';

import Cta from './cta';
import Column from './column';
import Row from './row';
import Link from './link';

const Wrapper = styled(Link)`
  display: block;
  position: relative;
  margin-bottom: 36px;
  text-decoration: none;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 14px;
    left: 14px;
    width: 100%;
    padding-top: calc(100% - 7px);
    z-index: -1;
    border: 4px solid ${({ theme }) => theme.colors.primary};
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.secondary};

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const TextWrapper = styled.div`
  margin-top: 24px;
  padding-left: 14px;
`;

const Title = styled.span`
  display: block;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
`;

const CtaImageColumn = styled(Column)`
  max-width: 368px;
  ${({ position }) => position !== 'left' && 'margin: 0 auto;'};

  ${up('md')} {
    margin: 0;
  }

  ${between('md', 'lg')} {
    width: calc(50% - 30px);
  }
`;

export const CtaImageRow = styled(Row)`
  margin-top: 32px;
  justify-content: space-around;

  ${up('lg')} {
    flex-wrap: wrap;
    justify-content: ${({ justify }) => (justify || 'flex-end')};
    margin-top: 0;
  }
`;

const CtaImage = ({
  title,
  image,
  link = '#',
  position,
}) => (
  <CtaImageColumn position={position}>
    <Wrapper to={link}>
      <ImageWrapper>
        {
          image.fluid
          && <Img fluid={image.fluid} alt={title} imgStyle={{ transition: 'transform .2s' }} />
        }
      </ImageWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        {
          link !== '#' && <Cta as="span" to={link}>read more</Cta>
        }
      </TextWrapper>
    </Wrapper>
  </CtaImageColumn>
);

export default CtaImage;
