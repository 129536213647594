import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import Cta from './cta';
import { PlyrLoadable } from './loadable-plyr';

const HeroImage = styled.div`
  margin: 0 auto;

  .img {
    position: relative;
  }
`;

const HeroCtaWrapper = styled.div`
  text-align: center;
  padding: 15px 0;
  
  ${up('lg')} {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 60px 0;
  }
`;

export default () => (
  <>
    <HeroImage>
      <div className="img hero-video">
        <PlyrLoadable
          options={{
            autoplay: true,
            controls: [],
            loop: { active: true },
            volume: 0,
            storage: { enabled: false },
          }}
          source={{
            type: 'video',
            sources: [
              {
                src: '685842249',
                provider: 'vimeo',
              },
            ],
          }}
        />
      </div>
      <HeroCtaWrapper>
        <Cta to="#show-more" nomargin>show more</Cta>
      </HeroCtaWrapper>

    </HeroImage>
  </>
);
